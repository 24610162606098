<template>
<div>
  <div class="page-content">
    <h1>Champs d'intervention</h1>
    <p>Dans cette section vous trouverez l'ensemble de nos champs d'intervention, nos publications et nos recherches.</p>
  </div>
  <div class="title-block green">
    <div class="page-content">
      <h2>Soin</h2>
    </div>
  </div>
  <ActionFile color="white">
    <p>Les usagers de drogues sont exposés à un certain nombre de risques sanitaires, pathologies et troubles psychiques. Dans le même temps, du fait-même de leur addiction, ils se voient souvent exclus du système de soins. La proposition de réponses adaptées implique un travail en réseau intégrant différentes spécialités.</p>
    <p> La politique de Réduction des Risques (RDR), développée en France depuis la fin des années quatre-vingt, permet de prévenir certains de ces risques par l’accès à des matériels stériles d’injection et favorise un meilleur accès aux soins par la prescription de traitements de substitution aux opiacés.</p>
    <p>Cette politique doit cependant être relayée sur le terrain, à partir de stratégies locales, par la diffusion d’informations auprès des publics concernés, la mise à disposition d’outils de prévention, mais également par la sensibilisation des acteurs de santé aux difficultés rencontrées par ces publics.</p>
    <p><router-link to="/publications">Découvrez nos publications sur le sujet</router-link></p>
  </ActionFile>
  <div class="title-block yellow">
    <div class="page-content">
      <h2>Accompagnement social</h2>
    </div>
  </div>
  <ActionFile color="white" :image="`${require('@/assets/des-action-puzzle.jpeg')}`">
    <p>Les conséquences sociales et sanitaires des usages problématiques de drogues conduisent généralement à l’exclusion d’un très grand nombre d’usagers.</p>
    <p>Leurs parcours émaillés d’échecs scolaires et professionnels, de périodes de détention, de ruptures familiales et de problèmes de santé relèvent souvent de la grande précarité.</p>
    <p>La prise en charge sociale et sanitaire doit s’inscrire dans une approche globale et être étayée d’un véritable accompagnement concret, souvent au long cours, dans une perspective de meilleur accès au droit commun et d’insertion.</p>
    <p>Cet accompagnement permet également une évolution de représentations réciproques : celles des usagers envers les institutions, mais également celles des professionnels de ces institutions envers les usagers de drogues.</p>
    <p><router-link to="/publications">Découvrez nos publications sur le sujet</router-link></p>
  </ActionFile>

<!--  <div class="action-wrapper" style="background-color: white">-->

<!--&lt;!&ndash;    <div class="action-content">-->
<!--      <div class="text">-->
<!--          <div style="flex: 1">-->
<!--            <p>Les conséquences sociales et sanitaires des usages problématiques de drogues conduisent généralement à l’exclusion d’un très grand nombre d’usagers.</p>-->
<!--            <p>Leurs parcours émaillés d’échecs scolaires et professionnels, de périodes de détention, de ruptures familiales et de problèmes de santé relèvent souvent de la grande précarité.</p>-->
<!--            <p>La prise en charge sociale et sanitaire doit s’inscrire dans une approche globale et être étayée d’un véritable accompagnement concret, souvent au long cours, dans une perspective de meilleur accès au droit commun et d’insertion.</p>-->
<!--            <p>Cet accompagnement permet également une évolution de représentations réciproques : celles des usagers envers les institutions, mais également celles des professionnels de ces institutions envers les usagers de drogues.</p>-->
<!--            <p><router-link to="/publications">Découvrez nos publication sur le sujet</router-link></p>-->
<!--          </div>-->
<!--      </div>-->
<!--      <div class="image right" v-bind:style="{backgroundImage: `url('${require('@/assets/des-action-puzzle.jpeg')}')`}"></div>-->
<!--    </div>-->
<!--  </div>&ndash;&gt;-->

  <div class="title-block orange">
    <div class="page-content">
      <h2>Insertion et accès à l’emploi</h2>
    </div>
  </div>
  <ActionFile>
    <p>L’élaboration d’un projet professionnel peut aider à renforcer un projet de soin et favoriser la régulation de consommations problématiques. L’hypothèse, souvent vérifiée, est que le soin n’est pas un préalable à l’insertion.</p>
<!--    <p>Les conséquences sociales et sanitaires des usages problématiques de drogues conduisent généralement à l’exclusion d’un très grand nombre d’usagers.</p>-->
<!--    <p>Leurs parcours émaillés d’échecs scolaires et professionnels, de périodes de détention, de ruptures familiales et de problèmes de santé relèvent souvent de la grande précarité.</p>-->
<!--    <p>La prise en charge sociale et sanitaire doit s’inscrire dans une approche globale et être étayée d’un véritable accompagnement concret, souvent au long cours, dans une perspective de meilleur accès au droit commun et d’insertion.</p>-->
<!--    <p>Cet accompagnement permet également une évolution de représentations réciproques : celles des usagers envers les institutions, mais également celles des professionnels de ces institutions envers les usagers de drogues.</p>-->
    <p><router-link to="/publications">Découvrez nos publications sur le sujet</router-link></p>
  </ActionFile>

  <div class="title-block blue">
    <div class="page-content">
      <h2>Prévention</h2>
    </div>
  </div>
  <ActionFile>
    <p>Une démarche de prévention des addictions doit s’appuyer tant sur la diffusion d’informations relatives aux différents risques liés à l’usage et à l’abus de drogues, que sur le renforcement des compétences psycho-sociales des publics jeunes.</p>
    <p>Doivent ainsi pouvoir être appréhendées les capacités de ces publics en matière de prévention par les pairs, dans la perspective de leur valorisation.</p>
<!--    <p>Deux autres aspects doivent également être appréhendés, dans une perspective de soutien et de renforcement :</p>-->
<!--      <ul style="margin-bottom: 1em">-->
<!--        <li>Les ressources des publics, et notamment leurs capacités en terme d’auto-régulation et de régulation (auprès de leurs pairs) de comportements à risques,</li>-->
<!--        <li>Les connaissances, savoirs et savoirs-faire ainsi que les besoins d’information des adultes et professionnels encadrant habituellement ces publics.</li>-->
<!--      </ul>-->
    <p><router-link to="/publications">Découvrez nos publications sur le sujet</router-link></p>
  </ActionFile>

  <div class="title-block purple">
    <div class="page-content">
      <h2>Formation</h2>
    </div>
  </div>
  <ActionFile>
    <p>De nombreux professionnels sont confrontés, en grande proximité et au quotidien, à des publics consommateurs. Il s’agit de considérer leurs savoirs et savoir-faire, de connaître leurs besoins afin de les aider à renforcer leurs compétences et au-delà, de construire un socle de connaissances communes et les bases d’un véritable travail en réseau sur le territoire.</p>
    <p><router-link to="/publications">Découvrez nos publications sur le sujet</router-link></p>
  </ActionFile>

  <div class="title-block red">
    <div class="page-content">
      <h2>Recherche</h2>
    </div>
  </div>
  <ActionFile>
    <p>La complexité de la question des usages de drogues n’en facilite pas l’approche pour la plupart des acteurs sociaux. Il est indispensable de pouvoir expérimenter collectivement de nouvelles stratégies d’intervention, à partir de différents champs professionnels, et d’en dégager des enseignements nous permettant d’améliorer nos capacités d’accompagnement des publics. La recherche action participative est une méthode parfaitement adaptée à une telle démarche.</p>
    <p><router-link to="/publications">Découvrez nos publications sur le sujet</router-link></p>
  </ActionFile>

  <div class="title-block green">
    <div class="page-content">
      <h2>Participation et productions collectives</h2>
    </div>
  </div>
  <ActionFile>
    <p>Le quotidien du centre d’accueil ainsi que certains de modules de prévention par les pairs sont étayés d’apports extérieurs favorisant la participation, accompagnant l’expression de la parole et l’implication dans de multiples « faire ensemble ». Au cours de ces dernières années, différentes démarches de création collective ont été initiées par plusieurs artistes à partir de la photographie, de l’écriture, du cinéma et du théâtre.</p>
    <p><router-link to="/publications">Découvrez nos publications sur le sujet</router-link></p>
  </ActionFile>
</div>
</template>

<script>
import ActionFile from "../components/ActionFile";

export default {
  name: "Actions",
  components: {ActionFile},
  computed: {
    backgroundPosition() {

      let x = this.$vssWidth

      return x / 2 + "px"
    }
  }
}
</script>

<style lang="scss" scoped>

.title-block {
  color: white;
}

.action-wrapper {

}

.action-wrapper.image {
  background-position: center;
}

.action-wrapper.right {
  .action-text {
    padding-right: 22%;
  }
}

.action-wrapper.image.right {
  background-position: right;
}

@media only screen and (max-width: 2100px) {

  .action-wrapper.right {
    .action-text {
      padding-right: 25%;
    }
  }

}

@media only screen and (max-width: 1900px) {

  .action-wrapper.right {
    .action-text {
      padding-right: 28%;
    }
  }

}

@media only screen and (max-width: 1700px) {

  .action-wrapper.right {
    .action-text {
      padding-right: 31%;
    }
  }

}

@media only screen and (max-width: 1500px) {


  .action-wrapper.right {
    .action-text {
      padding-right: 40%;
    }
  }

  .action-wrapper.image.right {
    background-position-x: 130%;
  }

}



@media only screen and (max-width: 1280px) {


}

@media only screen and (max-width: 920px) {

}

@media only screen and (max-width: 640px) {


}

@media only screen and (max-width: 420px) {


}
</style>

<template>
  <div :class="actionClass" v-bind:style="{
    backgroundImage: $vssWidth > 700 ? `url('${image}')` : 'none',
    backgroundPositionX: backgroundPositionX,
    backgroundPositionY: backgroundPositionY
  }">
    <div class="page-content action-text">
      <slot />

    </div>
    <div class="dummy" v-bind:style="{backgroundImage: `url('${image}')`}"></div>
  </div>
</template>

<script>
import { VueScreenSizeMixin } from 'vue-screen-size';
export default {
  name: "ActionFile",
  mixins: [VueScreenSizeMixin],
  props: {
    image: String,
    left: Boolean,
    color: String,
    noPadding: Boolean
  },
  data() {
    return {
      imageClass: this.left ? 'image left' : "image right"
    }
  },
  computed: {
    backgroundPositionX() {
      let x = this.$vssWidth
      return x < 700 ? "center" : x / 2 + "px"
    },
    backgroundPositionY() {
      let x = this.$vssWidth
      return x > 700 ? "50%" : "-79%"
    },
    actionClass() {
      let clazz = "action-wrapper"
      if (this.noPadding) clazz += " no-padding"
      if (this.color) clazz += ` ${this.color}`
      if (this.left) clazz += ` left`; else clazz += ` right`
      if (this.image) clazz += ` image`


      return clazz
    }
  }
}
</script>

<style lang="scss" scoped>

.action-wrapper {
  background-image: none;
  .dummy {
    display: none;
  }
}

.action-wrapper.image {
  background-position: center;
}

.action-wrapper.image.right {
  .action-text {
    padding-right: 22%;
  }
}

.action-wrapper.image.right {
  background-position: right;
}

.action-wrapper.no-padding {
  padding: 0;
  .action-text {
    padding: 0;
  }
}



@media only screen and (max-width: 2100px) {

  .action-wrapper.image.right {
    .action-text {
      padding-right: 25%;
    }
  }

}

@media only screen and (max-width: 1900px) {

  .action-wrapper.image.right {
    .action-text {
      padding-right: 28%;
    }
  }

}

@media only screen and (max-width: 1700px) {

  .action-wrapper.image.right {
    .action-text {
      padding-right: 31%;
    }
  }

}

@media only screen and (max-width: 1500px) {


  .action-wrapper.image.right {
    .action-text {
      padding-right: 40%;
    }
  }
}



@media only screen and (max-width: 1280px) {


}

@media only screen and (max-width: 920px) {

}

@media only screen and (max-width: 700px) {

  .action-wrapper.image {
    background-image: none !important;
    .dummy {
      display: block;
      height: 300px;
      width: 100%;
      background-size: cover;
      background-position: 50% 30%;
    }
  }

  .action-wrapper.image.right {
    .action-text {
      padding-right: 1em;
    }
  }
}

@media only screen and (max-width: 420px) {


}
</style>
